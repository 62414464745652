<!--  -->
<template>
    <div class="middleMap">
      <div class="middleMap-title">
        <div class="name">用户总计<span class="num">1963</span></div>
        <div class="name">预警统计<span class="num">37.7w</span></div>
        <div class="name">隐患统计<span class="num">1429</span></div>
        <div class="name">重点单位<span class="num">214</span></div>
        <div class="name">监测点数<span class="num">50703</span></div>
      </div>
      <div class="middleMap-top">
        <middleMapTop></middleMapTop>
      </div>
      <!-- <div class="middleMap-bottom">
        <middleMapBottom></middleMapBottom>
      </div> -->
    </div>
  </template>
  
  <script>
  import xuzhou from '../../assets/geo/xuzhou/xuzhou.json'
  import middleMapTop from './middleMapTop.vue'
  // import middleMapBottom from './middleMapBottom.vue'
  export default {
    name: "middleMap",
    components: {
      middleMapTop,
      // middleMapBottom
    },
    props: {
  
    },
    watch: {
  
    },
    computed: {
  
    },
    data() {
      return {
        option: null,
        myMiddleMapCharts: {}
      }
    },
    created() {
      if (!this.myMiddleMapCharts) {
        this.$nextTick(async () => {
  
          var geoCoordMap = {
            云龙区: [117.194589, 34.254805],
            新沂市: [118.345828, 34.368779],
            铜山区: [117.183894, 34.19288],
            睢宁县: [117.95066, 33.899222],
            泉山区: [117.182225, 34.262249],
            邳州市: [117.963923, 34.314708],
            沛县: [116.937182, 34.729044],
            贾汪区: [117.450212, 34.441642],
            鼓楼区: [117.192941, 34.269397],
            丰县: [116.592888, 34.696946],
          }
          var customerBatteryCityData = [
            { name: "云龙区", value: 20 },
            { name: "新沂市", value: 30 },
            { name: "铜山区", value: 50 },
            { name: "睢宁县", value: 70 },
            { name: "泉山区", value: 100 },
            { name: "邳州市", value: 10 },
            { name: "沛县", value: 60 },
            { name: "贾汪区", value: 40 },
            { name: "鼓楼区", value: 80 },
            { name: "丰县", value: 70 },
          ]
          this.myMiddleMapCharts = this.$echarts.init(document.querySelector('#middleMapCharts'))
          this.$echarts.registerMap('xuzhouMap', xuzhou);
          this.option = {
            // visualMap: {
            //   min: 0,
            //   max: 100,
            //   left: '20',
            //   bottom: '20',
            //   orient: 'horizontal',
            // },
            // backgroundColor: 'rgba(0,0,0,0.6)',
            tooltip: {
              trigger: 'item',
              show: true,
              enterable: true,
              textStyle: {
                fontSize: 20,
                color: '#fff'
              },
              backgroundColor: 'rgba(0,2,89,0.8)',
              formatter: '{b}<br />{c}'
            },
            dataZoom: {
              type: 'inside'
            },
            grid: {
              left: '5%',
              right: '5%',
              bottom: '3%',
              top: '25%',
            },
            geo: [
              {
                map: 'xuzhouMap',
                aspectScale: 0.9,
                roam: false, // 是否允许缩放
                zoom: 1, // 默认显示级别
                layoutSize: '95%',
                layoutCenter: ['50%', '61%'],
                itemStyle: {
                  normal: {
                    areaColor: {
                      type: 'linear-gradient',
                      x: 0,
                      y: 400,
                      x2: 0,
                      y2: 0,
                      colorStops: [{
                        offset: 0,
                        color: 'rgba(37,108,190,0.3)' // 0% 处的颜色
                      }, {
                        offset: 1,
                        color: 'rgba(15,169,195,0.3)' // 50% 处的颜色
                      }],
                      global: true // 缺省为 false
                    },
                    borderColor: '#4ecee6',
                    borderWidth: 1
                  },
                  emphasis: {
                    areaColor: {
                      type: 'linear-gradient',
                      x: 0,
                      y: 300,
                      x2: 0,
                      y2: 0,
                      colorStops: [{
                        offset: 0,
                        color: 'rgba(37,108,190,1)' // 0% 处的颜色
                      }, {
                        offset: 1,
                        color: 'rgba(15,169,195,1)' // 50% 处的颜色
                      }],
                      global: true // 缺省为 false
                    }
                  }
                },
                emphasis: {
                  itemStyle: {
                    areaColor: '#0160AD'
                  },
                  label: {
                    show: 0,
                    color: '#fff'
                  }
                },
                zlevel: 3
              },
              {
                map: 'xuzhouMap',
                aspectScale: 0.9,
                roam: false, // 是否允许缩放
                zoom: 1, // 默认显示级别
                layoutSize: '95%',
                layoutCenter: ['50%', '61%'],
                itemStyle: {
                  normal: {
                    borderColor: 'rgba(192,245,249,.6)',
                    borderWidth: 2,
                    shadowColor: '#2C99F6',
                    shadowOffsetY: 0,
                    shadowBlur: 120,
                    areaColor: 'rgba(29,85,139,.2)'
                  }
                },
                zlevel: 2,
                silent: true
              },
              {
                map: 'xuzhouMap',
                aspectScale: 0.9,
                roam: false, // 是否允许缩放
                zoom: 1, // 默认显示级别
                layoutSize: '95%',
                layoutCenter: ['50%', '62%'],
                itemStyle: {
                  // areaColor: '#005DDC',
                  areaColor: 'rgba(0,27,95,0.4)',
                  borderColor: '#004db5',
                  borderWidth: 1
                },
                zlevel: 1,
                silent: true
              }
            ],
            series: [
              // map
              {
                geoIndex: 0,
                // coordinateSystem: 'geo',
                showLegendSymbol: true,
                type: 'map',
                roam: false,
                label: {
                  normal: {
                    show: false,
                    textStyle: {
                      color: '#fff'
                    }
                  },
                  emphasis: {
                    show: false,
                    textStyle: {
                      color: '#fff'
                    }
                  }
                },
  
                itemStyle: {
                  normal: {
                    borderColor: '#2ab8ff',
                    borderWidth: 1.5,
                    areaColor: '#12235c'
                  },
                  emphasis: {
                    areaColor: '#2AB8FF',
                    borderWidth: 0,
                    color: 'red'
                  }
                },
                map: 'xuzhouMap', // 使用
                data: customerBatteryCityData
                // data: this.difficultData //热力图数据   不同区域 不同的底色
              },
              // 柱状体的主干
              {
                type: 'lines',
                zlevel: 5,
                effect: {
                  show: false,
                  // period: 4, //箭头指向速度，值越小速度越快
                  // trailLength: 0.02, //特效尾迹长度[0,1]值越大，尾迹越长重
                  // symbol: 'arrow', //箭头图标
                  // symbol: imgDatUrl,
                  symbolSize: 5 // 图标大小
                },
                lineStyle: {
                  width: 20, // 尾迹线条宽度
                  color: 'rgb(22,255,255, .6)',
                  opacity: 1, // 尾迹线条透明度
                  curveness: 0 // 尾迹线条曲直度
                },
                label: {
                  show: 0,
                  position: 'end',
                  formatter: '245'
                },
                silent: true,
                data: lineData()
              },
              // 柱状体的顶部
              {
                type: 'scatter',
                coordinateSystem: 'geo',
                geoIndex: 0,
                zlevel: 5,
                label: {
                  show: true,
                  formatter: function (p) {
                    return `${customerBatteryCityData[p.dataIndex].value}`
                  },
                  position: "top"
                },
                symbol: 'circle',
                symbolSize: [20, 10],
                itemStyle: {
                  color: 'rgb(22,255,255, 1)',
                  opacity: 1
                },
                silent: true,
                data: scatterData()
              },
              // 柱状体的底部
              {
                type: 'scatter',
                coordinateSystem: 'geo',
                geoIndex: 0,
                zlevel: 4,
                label: {
                  // 这儿是处理的
                  formatter: '{b}',
                  position: 'bottom',
                  color: '#fff',
                  fontSize: 12,
                  distance: 10,
                  show: true
                },
                symbol: 'circle',
                symbolSize: [20, 10],
                itemStyle: {
                  // color: '#F7AF21',
                  color: 'rgb(22,255,255, 1)',
                  opacity: 1
                },
                silent: true,
                data: scatterData2()
              },
              // 底部外框
              {
                type: 'scatter',
                coordinateSystem: 'geo',
                geoIndex: 0,
                zlevel: 4,
                label: {
                  show: false
                },
                symbol: 'circle',
                symbolSize: [40, 20],
                itemStyle: {
                  color: {
                    type: 'radial',
                    x: 0.5,
                    y: 0.5,
                    r: 0.5,
                    colorStops: [
                      {
                        offset: 0, color: 'rgb(22,255,255, 0)' // 0% 处的颜色
                      },
                      {
                        offset: .75, color: 'rgb(22,255,255, 0)' // 100% 处的颜色
                      },
                      {
                        offset: .751, color: 'rgb(22,255,255, 1)' // 100% 处的颜色
                      },
                      {
                        offset: 1, color: 'rgb(22,255,255, 1)' // 100% 处的颜色
                      }
                    ],
                    global: false // 缺省为 false
                  },
  
                  opacity: 1
                },
                silent: true,
                data: scatterData2()
              }
            ]
          }
          this.myMiddleMapCharts.setOption(this.option)
  
  
  
          // 动态计算柱形图的高度（定一个max）
          function lineMaxHeight() {
            const maxValue = Math.max(...customerBatteryCityData.map(item => item.value))
            return 0.19 / maxValue
          }
          // 柱状体的主干
          function lineData() {
            return customerBatteryCityData.map((item) => {
              return {
                coords: [geoCoordMap[item.name], [geoCoordMap[item.name][0], geoCoordMap[item.name][1] + item.value * lineMaxHeight()]]
              }
            })
          }
          // 柱状体的顶部
          function scatterData() {
            return customerBatteryCityData.map((item) => {
              return [geoCoordMap[item.name][0], geoCoordMap[item.name][1] + item.value * lineMaxHeight()]
            })
          }
          // 柱状体的底部
          function scatterData2() {
            return customerBatteryCityData.map((item) => {
              return {
                name: item.name,
                value: geoCoordMap[item.name]
              }
            })
          }
        })
      }
    },
    mounted() {
  
    },
    methods: {
  
    }
  }
  </script>
  <style lang="scss" scoped>
  /* @import url(); 引入css类 */
  .middleMap {
    position: relative;
    width: 100%;
    height: 900px;
    display: flex;
    flex-direction: column;
    background: url('../../assets/img/map_bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  
    .middleMap-title {
      width: 100%;
      height: 150px;
      font-family: FZZ;
      color: #E4F2FF;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .name {
        font-size: 14;
      }
  
      .num {
        font-size: 28;
      }
    }
  
    .middleMap-top {
      width: 100%;
      height: 900px;
      margin-bottom: 20px;
      // filter: blur(6.1px);
      // box-shadow: inset 0px 0px 4px 15px rgba(72, 177, 255, 0.04);
      box-sizing: border-box;
      // border: 1px solid #05B0FB;
      // background: linear-gradient(134deg, rgba(21, 119, 251, 0.37) 2%, rgba(9, 21, 43, 0) 56%);
    }
  
    .middleMap-bottom {
      width: 100%;
      height: 490px;
      // filter: blur(6.1px);
      box-shadow: inset 0px 0px 4px 15px rgba(72, 177, 255, 0.04);
      box-sizing: border-box;
      border: 1px solid #05B0FB;
      background: linear-gradient(134deg, rgba(21, 119, 251, 0.37) 2%, rgba(9, 21, 43, 0) 56%);
    }
  
  
  }
  </style>