<!--  -->
<template>
    <div class="rightTwo">
      <div class="chart-title">
        年度徐州市线上、线下消费总览
      </div>
      <div id="rightTwoCharts">
  
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "rightTwo",
    props: {
  
    },
    watch: {
  
    },
    computed: {
  
    },
    data() {
      return {
        option: null,
        myrightTwoCharts: null
      }
    },
    created() {
      if (!this.myrightTwoCharts) {
        this.$nextTick(async () => {
          this.option = {
            grid:{
              x: '15%',
              y: '30%',
              y2: '20%'
            },
            tooltip: {
              trigger: 'axis',
              position: function (pt) {
                return [pt[0], '10%'];
              }
            },
            legend: {
              show:true,
              right: 10,
              top: 40,
              textStyle: {
                color: '#fff'
              },
              data: ['线上','线下']
            },
            series: [
            {
                  name: 'gender',
                  type: 'pie',
                  center: ['25%', '50%'],
                  radius: ['40%', '70%'],
                  avoidLabelOverlap: false,
                  label: {
                    show: false,
                    position: 'center'
                  },
                  emphasis: {
                    label: {
                      show: true,
                      fontSize: 40,
                      fontWeight: 'bold'
                    }
                  },
                  labelLine: {
                    show: false
                  },
                  data: [
                    { name: '男', value: 46},
                    { name: '女', value: 54}
                  ]
                },

            ]
          };
          this.myrightTwoCharts = this.$echarts.init(document.querySelector('#rightTwoCharts'))
          this.myrightTwoCharts.setOption(this.option)
        })
      }
    },
    mounted() {
  
    },
    methods: {
      resize() {
        this.myrightTwoCharts.resize()
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  /* @import url(); 引入css类 */
  .rightTwo {
    display: flex;
    flex-direction: column;
    padding-bottom: 1.1%;
  }
  
  #rightTwoCharts {
    width: 385px;
    height: 270px;
    background: url("../../assets/img/charts_bg.png");
    background-size: cover;
    padding-bottom: 4%;
  }
  
  .chart-title {
    font-size: 20px;
    font-family: FZZ;
    font-weight: 700;
    width: 385px;
    height: 37.17px;
    color: #fff;
    font-style: italic;
    padding-left: 70px;
    background: url("../../assets/img/charts_title.png");
    background-size: 440px 100%;
    margin-bottom: 12px;
  }
  </style>