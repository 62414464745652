<!--  -->
<template>
    <div class="leftThree">
      <div class="chart-title">
        徐州市消费者分布
      </div>
      <div id="leftThreeCharts">
  
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "leftThree",
    props: {
  
    },
    watch: {
  
    },
    computed: {
  
    },
    data() {
      return {
        option: null,
        myLeftThreeCharts: null
      }
    },
    created() {
      if (!this.myLeftThreeCharts) {
  
        this.$nextTick(() => {
  
          this.option = {
              tooltip: {
                trigger: 'item'
              },
              legend: {
                top: '5%',
                right: 0,
                orient: 'vertical',
              },
              series: [
                {
                  name: 'gender',
                  type: 'pie',
                  center: ['25%', '50%'],
                  radius: ['40%', '70%'],
                  avoidLabelOverlap: false,
                  label: {
                    show: false,
                    position: 'center'
                  },
                  emphasis: {
                    label: {
                      show: true,
                      fontSize: 40,
                      fontWeight: 'bold'
                    }
                  },
                  labelLine: {
                    show: false
                  },
                  data: [
                    { name: '男', value: 46},
                    { name: '女', value: 54}
                  ]
                },
                {
                  name: 'age',
                  type: 'pie',
                  center: ['75%', '50%'],
                  radius: ['40%', '70%'],
                  avoidLabelOverlap: false,
                  label: {
                    show: false,
                    position: 'center'
                  },
                  emphasis: {
                    label: {
                      show: true,
                      fontSize: 40,
                      fontWeight: 'bold'
                    }
                  },
                  labelLine: {
                    show: false
                  },
                  data: [
                    { value: 1048, name: 'Search Engine' },
                    { value: 735, name: 'Direct' },
                    { value: 580, name: 'Email' },
                    { value: 484, name: 'Union Ads' },
                    { value: 300, name: 'Video Ads' }
                  ]
                }
              ]
            };
          this.myLeftThreeCharts = this.$echarts.init(document.querySelector('#leftThreeCharts'))
          this.myLeftThreeCharts.setOption(this.option)
        })
      }
    },
    mounted() {
  
    },
    methods: {
      resize() {
        this.myLeftThreeCharts.resize()
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  /* @import url(); 引入css类 */
  .leftThree {
    display: flex;
    flex-direction: column;
  }
  
  #leftThreeCharts {
    width: 412px;
    height: 244px;
    background: url("../../assets/img/charts_bg.png");
    background-size: cover;
  }
  
  .chart-title {
    font-size: 20px;
    font-family: FZZ;
    font-weight: 700;
    width: 385px;
    height: 37.17px;
    color: #fff;
    font-style: italic;
    padding-left: 70px;
    background: url("../../assets/img/charts_title.png");
    background-size: 440px 100%;
    margin-bottom: 12px;
  }
  </style>