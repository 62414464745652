<!--  -->
<template>
    <div class="leftTwo">
      <div class="chart-title">
        徐州市各行业年度消费额
      </div>
      <div id="leftTwoCharts">
  
      </div>
    </div>
  </template>
  
  <script>


  export default {
    name: "leftTwo",
    props: {
  
    },
    watch: {
  
    },
    computed: {
  
    },
    data() {
      return {
        option: null,
        myLeftTwoCharts: null
      }
    },
    created() {
      if (!this.myLeftTwoCharts) {
        this.$nextTick(() => {
          this.option = {
          xAxis: {
            type: 'value',
            axisLabel: {
              textStyle: {
                color: '#fff'
              }
            },
            splitLine: {
                lineStyle: {
                  interval: 'auto',
                  type: 'dashed',
                  color: 'rgba(75, 133, 240, 1)'
                }
              },
          },
          yAxis: {
            type: 'category',
            inverse: true,
            axisLabel: {
              textStyle: {
                color: '#fff'
              }
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            data: ['农业', '工业', '投资', '贸易', '服务业']
          },
          series: [
            {
              data: [750, 1100, 600, 80, 1200, 1600],
              type: 'bar',
              label: {
                show: true,
                position: 'right',
                barWidth: 20,
                textStyle: { 
                  color: '#fff',
                }
              },
              itemStyle: {
                borderColor: 'rgba(114, 255, 219, 1)',
                borderWidth: 1,
                color: new this.$echarts.graphic.LinearGradient(
                  0,0,1,0,
                  [{offset: 0, color: 'rgba(32, 78, 196, 1)'},
                  {offset: 1, color: 'rgba(58, 228, 139, 1)'}]
                )
              }
            }
          ]
        };
          this.myLeftTwoCharts = this.$echarts.init(document.querySelector('#leftTwoCharts'))
          this.myLeftTwoCharts.setOption(this.option)
        })
      }
    },
    mounted() {
  
    },
    methods: {
      resize() {
        this.myLeftTwoCharts.resize()
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  /* @import url(); 引入css类 */
  .leftTwo {
    display: flex;
    flex-direction: column;
  }
  
  #leftTwoCharts {
    width: 385px;
    height: 225px;
    background: url("../../assets/img/charts_bg.png");
    background-size: cover;
    padding-bottom: 4%;
  }
  
  .chart-title {
    font-size: 20px;
    font-family: FZZ;
    font-weight: 700;
    width: 385px;
    height: 37.17px;
    color: #fff;
    font-style: italic;
    padding-left: 70px;
    background: url("../../assets/img/charts_title.png");
    background-size: 440px 100%;
    margin-bottom: 12px;
  }
  </style>