<!--  -->
<template>
  <div class="leftOne">
    <div class="chart-title">
      徐州市年度总消费额及环比
    </div>
    <div id="leftOneCharts">

    </div>
  </div>
</template>

<script>
export default {
  name: "leftOne",
  props: {

  },
  watch: {

  },
  computed: {

  },
  data() {
    return {
      option: null,
      myLeftOneCharts: null
    }
  },
  created() {
    if (!this.myLeftOneCharts) {
      this.$nextTick(async () => {
        this.option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          legend: {
            top: '5%',
            right: '5%',
            textStyle: {
              color: '#fff'
            },
            data: ['环比']
          },
          xAxis: [
            {
              type: 'category',
              data: ['一季度', '二季度', '三季度', '四季度',],
              axisPointer: {
                type: 'shadow'
              },
              axisLabel:{
                textStyle: {
                  color: '#fff'
                }
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: '单位(亿元)',
              interval: 500,
              splitLine: {
                lineStyle: {
                  interval: 'auto',
                  type: 'dashed',
                  color: 'rgba(75, 133, 240, 1)'
                }
              },
              axisLabel:{
                textStyle: {
                  color: '#fff'
                }
              }
            },
            {
              type: 'value',
              min: -25,
              max: 100,
              interval: 25,
              axisLabel: {
                formatter: '{value} %'
              },
              splitLine: {
                show: false,
                lineStyle: {
                  interval: 'auto',
                  type: 'dashed',
                  color: 'rgba(75, 133, 240, 1)'
                }
              },
              axisLabel:{
                textStyle: {
                  color: '#fff'
                }
              }
            }
          ],
          series: [
            {
              name: 'Evaporation',
              type: 'bar',
              tooltip: {
                valueFormatter: function (value) {
                  return value + '亿元';
                }
              },
              itemstyle: {
                color: 'rgba(132, 225, 255, 0.1)'
              },
              data: [
                1200, 1550, 1880, 1500
              ]
            },
            {
              name: '环比',
              type: 'line',
              yAxisIndex: 1,
              tooltip: {
                valueFormatter: function (value) {
                  return value + ' °C';
                }
              },
              data: [10, 5, 10, 10]
            }
          ]
        };
        this.myLeftOneCharts = this.$echarts.init(document.querySelector('#leftOneCharts'))
        this.myLeftOneCharts.setOption(this.option)
      })
    }
  },
  mounted() {

  },
  methods: {
    resize() {
      this.myLeftOneCharts.resize()
    }
  }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.leftOne {
  display: flex;
  flex-direction: column;
  padding-bottom: 1.1%;
}

#leftOneCharts {
  width: 385px;
  height: 270px;
  background: url("../../assets/img/charts_bg.png");
  background-size: cover;
  padding-bottom: 4%;
}

.chart-title {
  font-size: 20px;
  font-family: FZZ;
  font-weight: 700;
  width: 385px;
  height: 37.17px;
  color: #fff;
  font-style: italic;
  padding-left: 70px;
  background: url("../../assets/img/charts_title.png");
  background-size: 440px 100%;
  margin-bottom: 12px;
}
</style>