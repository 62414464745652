import { render, staticRenderFns } from "./leftTwo.vue?vue&type=template&id=b4a53310&scoped=true&"
import script from "./leftTwo.vue?vue&type=script&lang=js&"
export * from "./leftTwo.vue?vue&type=script&lang=js&"
import style0 from "./leftTwo.vue?vue&type=style&index=0&id=b4a53310&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4a53310",
  null
  
)

export default component.exports