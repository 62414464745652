<!--  -->
<template>
    <div class="rightThree">
      <div class="chart-title">
        热门app使用人数排行
      </div>
      <div id="rightThreeCharts">
  
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "rightThree",
    props: {
  
    },
    watch: {
  
    },
    computed: {
  
    },
    data() {
      return {
        option: null,
        myrightThreeCharts: null
      }
    },
    created() {
      if (!this.myrightThreeCharts) {
        this.$nextTick(async () => {
          this.option = {
          xAxis: {
            type: 'value',
            name: '单位(万人)',
            nameLocation: 'end',
            axisLabel: {
              textStyle: {
                color: '#fff'
              }
            },
            splitLine: {
              show: false
            }
          },
          yAxis: {
            type: 'category',
            inverse: true,
            axisLabel: {
              textStyle: {
                color: '#fff'
              }
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            data: ['美团', '饿了么', '携程', '飞猪', '大众点评']
          },
          series: [
            {
              data: [800, 659, 500, 320, 280, ],
              type: 'bar',
              showBackground: true,
              backgroundStyle: {
                color: 'rgba(255, 255, 255, 0)',
                borderColor: "rgba(255, 255, 255, 0.50)",
                borderRadius: 4,
                borderWidth: 0.5
              },
              label: {
                show: true,
                position: 'right',
                color: '#fff'
              }
            }
          ]
        };
          this.myrightThreeCharts = this.$echarts.init(document.querySelector('#rightThreeCharts'))
          this.myrightThreeCharts.setOption(this.option)
        })
      }
    },
    mounted() {
  
    },
    methods: {
      resize() {
        this.myrightThreeCharts.resize()
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  /* @import url(); 引入css类 */
  .rightThree {
    display: flex;
    flex-direction: column;
    padding-bottom: 1.1%;
  }
  
  #rightThreeCharts {
    width: 385px;
    height: 270px;
    background: url("../../assets/img/charts_bg.png");
    background-size: cover;
    padding-bottom: 4%;
  }
  
  .chart-title {
    font-size: 20px;
    font-family: FZZ;
    font-weight: 700;
    width: 385px;
    height: 37.17px;
    color: #fff;
    font-style: italic;
    padding-left: 70px;
    background: url("../../assets/img/charts_title.png");
    background-size: 440px 100%;
    margin-bottom: 12px;
  }
  </style>